$c-black: #000;
$c-mine-shaft: #333;
$c-tundora: #4d4d4d;
$c-dove-gray: #666;
$c-gray: #939393;
$c-dusty-gray: #999;
$c-nobel: #b5b5b5;
$c-silver: #ccc;
$c-alto: #ddd;
$c-mercury: #e5e5e5;
$c-gallery: #eee;
$c-concrete: #f3f3f3;
$c-white: #fff;

$c-red-berry: #990d00;
$c-pippin: #ffe7e6;
$c-dusty-gray: #999;

$c-shiraz: #a0072b;

$c-primary: $c-shiraz;

$base-font-size: 18px;
$base-font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
$base-line-height: 1.33333;
$heading-font-family: $base-font-family;
$heading-line-height: 1.15;

$nav-hamburger: '(max-width: 1000px)';
$nav-hamburger-greater: '(min-width: 1001px)';
